
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import {ROUTE_HOME} from "@/router/routes";
import PaypalRepository from "@/api/repositories/PaypalRepository";

// TODO: currently not used
// enum PaymentStatus {
//   SUCCESS = 'SUCCESS',
//   PENDING = 'PENDING',
//   ERROR = 'ERROR'
// }

@Component({
  components: {
    LogoComponent: () => import(
      /* webpackChunkName: "LogoComponent" */
      '@/components/Logo.component.vue'
    ),
  }
})
export default class PaymentSettledView extends mixins(ErrorMessageHandlerMixin) {
  /**
   * timer to periodically ask for the status
   * @private
   */
  private timer?: NodeJS.Timeout;

  /**
   * max amount of retries to ask for the status
   * (currently application is asking for a maximum of 5 times)
   * @private
   */
  private limit: number = 5;

  /**
   * number of tries the status was fetched
   * @private
   */
  private retries: number = 0;

  /**
   * flag that indicates if the status is tried to fetch
   * @private
   */
  private isCheckingStatus: boolean = false;

  /**
   * flag that indicates that the payment succeeded
   * @private
   */
  private succeeded: boolean = false;

  /**
   * transactionId that is passed inside the return url
   * @private
   */
  private transactionId?: string;

  /**
   * flag that indicates that the status couldn't be fetched
   * @private
   */
  private unableToFetch: boolean = false;

  /**
   * flag that indicates if an error occurred
   * @private
   */
  private hasError: boolean = false;

  /**
   * gets the token from the url and tries to fetch the status,
   * checks the status in an interval of two seconds for five times,
   * so at a maximum of ten seconds
   * @private
   */
  private async created() {
    const agent = navigator.userAgent;
    if(agent === 'merways-app') {
      return;
    }

    this.succeeded = true;

    // this.transactionId = this.$route.query.token as string ?? undefined;
    // if(!this.transactionId) {
    //   this.hasError = true;
    //   return;
    // }
    //
    // this.isCheckingStatus = true;
    // this.timer = setInterval(this.checkPaymentStatus, 2000);
  }

  // TODO: removed for now
  // /**
  //  * checks the status of the payment
  //  * @private
  //  */
  // private async checkPaymentStatus(): Promise<void> {
  //   // increases retry count and checks if the max amount of retries was reached
  //   this.retries++;
  //   if(this.retries >= this.limit) {
  //     this.clearTimer();
  //     this.unableToFetch = true;
  //   }
  //
  //   if(!this.transactionId) return;
  //   const result = PaymentStatus.ERROR;
  //   this.validateStatus(result);
  //   const response = await PaypalRepository.getPaymentStatus(this.transactionId, '' /* TODO */);
  // }
  //
  // /**
  //  * clears the timer (to avoid another status fetch)
  //  * sets the is checking status flag to false
  //  * @private
  //  */
  // private clearTimer() {
  //   if(this.timer) clearInterval(this.timer);
  //   this.retries = 0;
  //   this.isCheckingStatus = false;
  // }
  //
  // /**
  //  * validates the status that was fetched
  //  * @param status
  //  * @private
  //  */
  // private validateStatus(status: PaymentStatus) {
  //   switch(status) {
  //     // sets success flag
  //     case PaymentStatus.SUCCESS:
  //       this.succeeded = true;
  //       this.clearTimer();
  //       break;
  //     case PaymentStatus.PENDING:
  //       // on pending it is doing nothing (because another fetch is scheduled automatically)
  //       break;
  //     // sets error flag
  //     case PaymentStatus.ERROR:
  //       this.hasError = true;
  //       this.clearTimer();
  //       break;
  //   }
  // }

  /**
   * On Logout handler
   */
  private async routeToApplication() {
    // redirects to login view
    if(this.$route.name !== ROUTE_HOME) await this.$router.replace({name: ROUTE_HOME});
  }
}
